import React, { useEffect, useState, useRef, useContext } from 'react';
import { Container, GlobalStyles, Fade } from '@mui/material'; // Import Fade
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import useProgress from '../hooks/useProgress';
import { steps } from '../constants/steps';
import getStepContent from '../utils/getStepContent';

import HeroSection from './homepage_components/HeroSection';
import CreateStoryButtons from './homepage_components/CreateStoryButtons';
import Snackbars from './homepage_components/Snackbars';
import ReCAPTCHAComponent from './homepage_components/ReCAPTCHAComponent';

import useCreateStory from '../hooks/useCreateStory';

import StoryForm from '../components/StoryForm';
import LoadingBackdrop from '../components/LoadingBackdrop';

import { getCreateStoryTimestamps } from '../utils/rateLimiter';

import LoginPromptModal, { showLoginModal } from '../components/LoginPromptModal';
import { UserStatusContext } from '../contexts/UserStatusContext';
import { useBrand } from '../contexts/BrandContext';

const HomePage = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = localStorage.getItem('selectedLanguage') || i18n.language;

  // Existing state variables
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [formValues, setFormValues] = useState(() => {
    try {
      const savedFormValues = sessionStorage.getItem('formValues');
      return savedFormValues
        ? JSON.parse(savedFormValues)
        : {
            hoofdrolspelers: '',
            verhaallocatie: '',
            verhaallijn: '',
            leesniveau: '',
            verhaalLengte: 1000,
          };
    } catch (error) {
      console.error('Failed to parse formValues from sessionStorage:', error);
      return {
        hoofdrolspelers: '',
        verhaallocatie: '',
        verhaallijn: '',
        leesniveau: '',
        verhaalLengte: 1000,
      };
    }
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [openLoadingSnackbar, setOpenLoadingSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openShareSnackbar, setOpenShareSnackbar] = useState(false);
  const [showContent, setShowContent] = useState(true);

  // New state for button visibility
  const [buttonsVisible, setButtonsVisible] = useState(false);

  // Gebruik useProgress hier en haal startProgress, progress en clearProgress op
  const { progress, startProgress, clearProgress } = useProgress();

  const bottomRef = useRef(null);
  const topRef = useRef(null);

  const recaptchaRef = useRef(null);

  const [remainingRequests, setRemainingRequests] = useState(5);

  // Consume the UserStatusContext
  const { subscriptionStatus, userCredits } = useContext(UserStatusContext);
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

  // Save formValues in sessionStorage on every change
  useEffect(() => {
    sessionStorage.setItem('formValues', JSON.stringify(formValues));
  }, [formValues]);

  // Automatically mark steps as completed based on formValues
  useEffect(() => {
    const newCompleted = {};
    if (formValues.hoofdrolspelers.trim() !== '') {
      newCompleted[0] = true;
    }
    if (formValues.verhaallocatie.trim() !== '') {
      newCompleted[1] = true;
    }
    if (formValues.verhaallijn.trim() !== '') {
      newCompleted[2] = true;
    }
    if (formValues.leesniveau) {
      newCompleted[3] = true;
    }
    if (formValues.verhaalLengte >= 250 && formValues.verhaalLengte <= 2000) {
      newCompleted[4] = true;
    }
    setCompleted(newCompleted);
  }, [formValues]);

  // Determine if the form is valid
  const isFormValid =
  formValues.hoofdrolspelers.trim() !== '' &&
  formValues.verhaallocatie.trim() !== '' &&
  formValues.verhaallijn.trim() !== '' &&
  formValues.leesniveau.trim() !== '';

  // Handle form changes
  const handleChange = (field) => (event, newValue) => {
    setFormValues({
      ...formValues,
      [field]: newValue !== undefined ? newValue : event.target.value,
    });
  };

  // Pass language as an extra parameter to useCreateStory
  const {
    handleStandardSubmit,
    handlePremiumSubmit,
    onReCAPTCHAChange,
    uuid,
  } = useCreateStory(
    formValues,
    subscriptionStatus,
    setErrorMessage,
    setOpenErrorSnackbar,
    setIsLoading,
    setOpenLoadingSnackbar,
    setOpenSuccessSnackbar,
    clearProgress,
    recaptchaRef,
    isFormValid,
    isLoading,         // Pass isLoading to the hook
    startProgress,     // Pass startProgress to the hook
    currentLanguage     // Pass the current language
  );

  // Wrapper for Standard Submit
  const handleStandardSubmitWithAuth = () => {
    if (!isAuthenticated) {
      showLoginModal(); // Trigger the login modal
      return;
    }
    handleStandardSubmit(); // Proceed with story creation
  };

  // Wrapper for Premium Submit
  const handlePremiumSubmitWithAuth = () => {
    if (!isAuthenticated) {
      showLoginModal(); // Trigger the login modal
      return;
    }
    handlePremiumSubmit(); // Proceed with premium story creation
  };

  // Close snackbars
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenLoadingSnackbar(false);
    setOpenErrorSnackbar(false);
    setOpenSuccessSnackbar(false);
    setOpenShareSnackbar(false);

    if (openErrorSnackbar) {
      setErrorMessage('');
    }
  };

  const { brand } = useBrand();

  // Scroll down when the form is valid
  useEffect(() => {
    if (isFormValid && bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isFormValid]);

  // Scroll up when the story is generated
  useEffect(() => {
    if (uuid && topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [uuid]);

  // Update remainingRequests on initialization and every minute
  useEffect(() => {
    const updateRemainingRequests = () => {
      const oneHourAgo = Date.now() - 60 * 60 * 1000;
      const timestamps = getCreateStoryTimestamps().filter(
        (timestamp) => timestamp > oneHourAgo
      );
      setRemainingRequests(5 - timestamps.length);
    };

    updateRemainingRequests();

    const interval = setInterval(() => {
      updateRemainingRequests();
    }, 60000); // Update every minute

    return () => clearInterval(interval);
  }, []);

  // Determine the first day of the next month
  const getFirstDayOfNextMonth = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + 1, 1);
  };

  // New useEffect for button visibility with 500ms delay
  useEffect(() => {
    const timer = setTimeout(() => {
      setButtonsVisible(true);
    }, 500); // 500ms delay

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {/* Global Styles for Bounce Animation */}
      <GlobalStyles
        styles={{
          '@keyframes bounce': {
            '0%, 20%, 50%, 80%, 100%': {
              transform: 'translateY(0)',
            },
            '40%': {
              transform: 'translateY(-10px)',
            },
            '60%': {
              transform: 'translateY(-5px)',
            },
          },
        }}
      />

      {/* Render the LoginPromptModal */}
      <LoginPromptModal />

      {/* Main Content */}
      <Container
        maxWidth="md"
        sx={{
          mt: 6,
          mb: 6,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* Attach the topRef to a wrapping div */}
        <div ref={topRef}>
          {showContent && (
            <div>
              {/* HeroSection */}
              <HeroSection />

              {/* StoryForm Component */}
              <StoryForm
                activeStep={activeStep}
                steps={steps}
                completed={completed}
                getStepContent={(step) =>
                  getStepContent(step, formValues, handleChange, brand, t)
                }
                handleNext={() => setActiveStep((prev) => prev + 1)}
                handleBack={() => setActiveStep((prev) => prev - 1)}
                isLoading={isLoading}
                formValues={formValues}
                setActiveStep={setActiveStep}
                handleChange={handleChange}
              />

              {/* ReCAPTCHAComponent */}
              <ReCAPTCHAComponent
                recaptchaRef={recaptchaRef}
                onReCAPTCHAChange={onReCAPTCHAChange}
              />

              {/* Conditionally render CreateStoryButtons with Fade after 500ms */}
              <Fade in={buttonsVisible} timeout={500}>
                <div>
                  <CreateStoryButtons
                    isFormValid={isFormValid}
                    isLoading={isLoading}
                    handleStandardSubmit={handleStandardSubmitWithAuth}
                    handlePremiumSubmit={handlePremiumSubmitWithAuth}
                    subscriptionStatus={subscriptionStatus}
                    isAuthenticated={isAuthenticated}
                    openLoginModal={showLoginModal}
                  />
                </div>
              </Fade>
            </div>
          )}
        </div>

        {/* Snackbars */}
        <Snackbars
          openLoadingSnackbar={openLoadingSnackbar}
          openErrorSnackbar={openErrorSnackbar}
          openSuccessSnackbar={openSuccessSnackbar}
          openShareSnackbar={openShareSnackbar}
          handleCloseSnackbar={handleCloseSnackbar}
          errorMessage={errorMessage}
        />

        {/* Loading Backdrop */}
        <LoadingBackdrop isLoading={isLoading} progress={progress} />

        {/* Dummy div for scrolling down */}
        <div ref={bottomRef} />
      </Container>
    </>
  );
};

export default HomePage;
