import { createTheme } from '@mui/material/styles';
import { grey, amber, red } from '@mui/material/colors';
import adventureImage from './adventure.png';
import storybuzzLogo from './storybuzz_logo.webp';

// Common Typography
const commonTypography = {
  fontFamily: `'Roboto', 'Helvetica', 'Arial', sans-serif`,
  h1: { fontSize: '2.5rem', fontWeight: 500, letterSpacing: '0.05em' },
  h2: { fontSize: '2rem', fontWeight: 500, letterSpacing: '0.05em' },
  h3: { fontSize: '1.75rem', fontWeight: 500, letterSpacing: '0.05em' },
  h4: { fontSize: '1.5rem', fontWeight: 500, letterSpacing: '0.05em' },
  h5: { fontSize: '1.25rem', fontWeight: 500, letterSpacing: '0.05em' },
  h6: { fontSize: '1rem', fontWeight: 500, letterSpacing: '0.05em' },
  subtitle1: { fontSize: '1.2rem', fontStyle: 'italic' },
  body1: { fontSize: '1.2rem', lineHeight: 1.8 },
  body2: { fontSize: '1.1rem', lineHeight: 1.7 },
  button: { textTransform: 'none', fontWeight: 600, fontSize: '1rem' },
};

// Default Palette
const defaultPalette = {
  primary: { light: '#b39ddb', main: '#7e57c2', dark: '#4d2c91', contrastText: '#fff' },
  secondary: { light: grey[300], main: grey[500], dark: grey[700], contrastText: '#fff' },
  accent: { light: amber[300], main: amber[500], dark: amber[700], contrastText: '#000' },
  background: { default: grey[100] },
  text: { primary: '#333333', secondary: '#555555' },
};

// StoryBuzz PaletteEE1D52
const storybuzzPalette = {
  primary: { light: '#A0E0E7', main: '#69C9D0', dark: '#3BAEA5', contrastText: '#FFFFFF' },
  secondary: { light: '#FF6F82', main: '#EE1D52', dark: '#B3153A', contrastText: '#FFFFFF' },
  error: { main: red.A400 },
  background: { default: '#FFFFFF', paper: '#F9F9F9' },
  text: { primary: '#000000', secondary: grey[700] },
};

// Common Components
const commonComponents = {
  MuiButton: {
    styleOverrides: {
      containedPrimary: {
        backgroundColor: (theme) => theme.palette.primary.main,
        '&:hover': { backgroundColor: (theme) => theme.palette.primary.dark },
      },
      containedSecondary: {
        backgroundColor: (theme) => theme.palette.secondary.main,
        '&:hover': { backgroundColor: (theme) => theme.palette.secondary.dark },
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        transition: 'color 0.3s ease',
        '&:hover': { color: (theme) => theme.palette.primary.light },
        '&:focus, &:active': { color: (theme) => theme.palette.primary.light },
      },
    },
  },
  // NEW OVERRIDES FOR INPUT FIELDS & ICONS
  MuiInput: {
    styleOverrides: {
      root: {
        color: (theme) => theme.palette.text.primary,
        '&:hover:not(.Mui-disabled):before': {
          borderBottomColor: (theme) => theme.palette.primary.main,
        },
        '&.Mui-focused:after': {
          borderBottomColor: (theme) => theme.palette.primary.dark,
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: (theme) => theme.palette.text.secondary,
        '&.Mui-focused': {
          color: (theme) => theme.palette.primary.main,
        },
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        color: (theme) => theme.palette.primary.main, // Icon color
      },
    },
  },
};

// Default Theme
const defaultTheme = createTheme({
  palette: defaultPalette,
  typography: {
    ...commonTypography,
    h1: { ...commonTypography.h1, color: defaultPalette.primary.main },
    h2: { ...commonTypography.h2, color: defaultPalette.primary.main },
    h3: { ...commonTypography.h3, color: defaultPalette.primary.main },
  },
  components: commonComponents,
  hero: {
    image: adventureImage,
    mainHeading: 'Maak je eigen superleuke kinderavontuur! 🌟🚀',
    subHeading:
      'Welkom bij onze verhalenmaker! ✨ In een paar simpele stapjes maak je een persoonlijk verhaal voor jouw kind(eren). Perfect om van te genieten. Volg de stappen hieronder en laat de magie beginnen! 📖',
  },
});

// StoryBuzz Theme
const storybuzzTheme = createTheme({
  palette: storybuzzPalette,
  typography: {
    ...commonTypography,
    h1: { ...commonTypography.h1, color: storybuzzPalette.secondary.main },
    h2: { ...commonTypography.h2, color: storybuzzPalette.secondary.main },
    h3: { ...commonTypography.h3, color: storybuzzPalette.secondary.main },
  },
  components: commonComponents,
  hero: {
    image: storybuzzLogo,
    mainHeading: 'Ontdek coole verhalen vol avontuur! 🎉🚀',
    subHeading:
      'Stap in de wereld van StoryBuzz en creëer je eigen spannende verhaal. Perfect voor tieners die houden van actie, mysterie en fantasie. Klaar om jezelf te verbazen? Laten we beginnen! 🚀✨',
  },
});

// Export Themes
export const themes = { default: defaultTheme, storybuzz: storybuzzTheme };
export const getTheme = (brandType) =>
  brandType === 'storybuzz' ? storybuzzTheme : defaultTheme;

export default defaultTheme;
