import React from 'react';
import { Typography, Box, Container, Link as MuiLink } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';

const PrivacybeleidPage = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom align="center">
        {t('privacyBeleid.title')}
      </Typography>

      <Box sx={{ mt: 4, textAlign: 'left' }}>
        <Typography variant="body1" paragraph>
          {t('privacyBeleid.introduction')}
        </Typography>

        <Typography variant="h6" gutterBottom>
          {t('privacyBeleid.contact.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          <Trans
            i18nKey="privacyBeleid.contact.info"
            components={{
              link: <MuiLink href="https://www.verhalen-maker.nl/" target="_blank" rel="noopener noreferrer" />,
              email: <MuiLink href="mailto:info@verhalen-maker.nl" />,
              br: <br />
            }}
          />
        </Typography>

        <Typography variant="h6" gutterBottom>
          {t('privacyBeleid.dataProcessed.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('privacyBeleid.dataProcessed.introduction')}
        </Typography>
        <Typography variant="body1" component="ul">
          {t('privacyBeleid.dataProcessed.list', { returnObjects: true }).map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </Typography>

        <Typography variant="body1" paragraph>
          <Trans
            i18nKey="privacyBeleid.sensitiveData.text"
            components={{
              email: <MuiLink href="mailto:info@verhalen-maker.nl" />,
              br: <br />
            }}
          />
        </Typography>

        <Typography variant="h6" gutterBottom>
          {t('privacyBeleid.processingPurposes.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('privacyBeleid.processingPurposes.introduction')}
        </Typography>
        <Typography variant="body1" component="ul">
          {t('privacyBeleid.processingPurposes.list', { returnObjects: true }).map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </Typography>

        <Typography variant="h6" gutterBottom>
          {t('privacyBeleid.automatedDecision.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('privacyBeleid.automatedDecision.text')}
        </Typography>

        <Typography variant="h6" gutterBottom>
          {t('privacyBeleid.dataRetention.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          <Trans
            i18nKey="privacyBeleid.dataRetention.text"
            components={{
              email: <MuiLink href="mailto:info@verhalen-maker.nl" />,
            }}
          />
        </Typography>

        <Typography variant="h6" gutterBottom>
          {t('privacyBeleid.dataSharing.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('privacyBeleid.dataSharing.text')}
        </Typography>

        <Typography variant="h6" gutterBottom>
          {t('privacyBeleid.cookies.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('privacyBeleid.cookies.paragraph1')}
        </Typography>
        <Typography variant="body1" paragraph>
          <Trans
            i18nKey="privacyBeleid.cookies.paragraph2"
            components={{
              link: <MuiLink href="https://veiliginternetten.nl/themes/situatie/cookies-wat-zijn-het-en-wat-doe-ik-ermee/" target="_blank" rel="noopener noreferrer" />,
            }}
          />
        </Typography>

        <Typography variant="h6" gutterBottom>
          {t('privacyBeleid.dataAccess.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('privacyBeleid.dataAccess.paragraph1')}
        </Typography>
        <Typography variant="body1" paragraph>
          <Trans
            i18nKey="privacyBeleid.dataAccess.paragraph2"
            components={{
              email: <MuiLink href="mailto:info@verhalen-maker.nl" />,
            }}
          />
        </Typography>
        <Typography variant="body1" paragraph>
          <Trans
            i18nKey="privacyBeleid.dataAccess.paragraph3"
            components={{
              link: <MuiLink href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons" target="_blank" rel="noopener noreferrer" />,
            }}
          />
        </Typography>

        <Typography variant="h6" gutterBottom>
          {t('privacyBeleid.security.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          <Trans
            i18nKey="privacyBeleid.security.paragraph1"
            components={{
              email: <MuiLink href="mailto:info@verhalen-maker.nl" />,
            }}
          />
        </Typography>
        <Typography variant="body1" paragraph>
          <Trans
            i18nKey="privacyBeleid.security.paragraph2"
            components={{
              br: <br />,
            }}
          />
        </Typography>

        <Typography variant="body2" color="textSecondary" align="right" sx={{ mt: 4 }}>
          {t('privacyBeleid.lastUpdated')}
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacybeleidPage;
