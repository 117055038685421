import React from 'react';
import { Box, Typography, useTheme, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SlideInHero = styled(Box)(({ theme }) => ({
  position: 'relative',
  left: '50%',
  right: '50%',
  marginLeft: '-50vw',
  marginRight: '-50vw',
  width: '100vw',
  height: '400px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  animation: 'slideIn 1s ease-out forwards',
  '@keyframes slideIn': {
    '0%': { transform: 'translateX(-100%)', opacity: 0 },
    '100%': { transform: 'translateX(0)', opacity: 1 },
  },
}));

const HeroSection = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { image } = theme.hero || {};

  return (
    <>
      <SlideInHero sx={{ backgroundImage: `url(${image})` }} />
      <Box sx={{ textAlign: 'center', px: 2 }}>
        <Typography variant="h3" sx={{ mt: 4, mb: 4 }}>
          {t('hero.mainHeading')}
        </Typography>
        <Typography variant="subtitle1" sx={{ mt: 2, mb: 6 }}>
          {t('hero.subHeading')}
        </Typography>
      </Box>
    </>
  );
};

export default HeroSection;
