// src/pages/VeelGesteldeVragen.js
import React from 'react';
import { Typography, Box, Container, List, ListItem, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';

const VeelGesteldeVragen = () => {
  const { t } = useTranslation();
  const vragenAntwoorden = t('veelGesteldeVragen.questions', { returnObjects: true });
  
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom align="center">
        {t('veelGesteldeVragen.title')}
      </Typography>
      <Box>
        <List>
          {vragenAntwoorden.map((item, index) => (
            <ListItem key={index} sx={{ mb: 2 }}>
              <ListItemText
                primary={<Typography variant="h6">{item.vraag}</Typography>}
                secondary={<Typography variant="body1">{item.antwoord}</Typography>}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Container>
  );
};

export default VeelGesteldeVragen;
