// src/pages/PaymentSuccess.js
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Typography, Link, Container, CircularProgress } from '@mui/material';
import { UserStatusContext } from '../contexts/UserStatusContext';
import { useTranslation, Trans } from 'react-i18next';

const PaymentSuccess = () => {
  const { t } = useTranslation();
  const { subscriptionStatus, refreshUserStatus } = useContext(UserStatusContext);
  const [pollingStopped, setPollingStopped] = useState(false);
  const pollCountRef = useRef(0);
  const intervalRef = useRef(null);

  useEffect(() => {
    // Start polling every 5 seconds
    intervalRef.current = setInterval(async () => {
      pollCountRef.current++;
      await refreshUserStatus();

      // Stop polling after 24 polls (2 minutes) if not premium
      if (pollCountRef.current >= 24 && subscriptionStatus !== 'premium') {
        clearInterval(intervalRef.current);
        setPollingStopped(true);
      }
    }, 5000);

    return () => clearInterval(intervalRef.current);
  }, [refreshUserStatus, subscriptionStatus]);

  useEffect(() => {
    if (subscriptionStatus === 'premium') {
      clearInterval(intervalRef.current);
    }
  }, [subscriptionStatus]);

  // Show spinner as long as polling is active and the status isn't premium.
  const isPolling = subscriptionStatus !== 'premium' && !pollingStopped;

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '60vh',
        }}
      >
        {isPolling && <CircularProgress sx={{ mb: 2 }} />}
        {subscriptionStatus === 'premium' ? (
          <>
            <Typography variant="h4" gutterBottom align="center">
              {t('paymentSuccess.premiumTitle')}
            </Typography>
            <Typography variant="body1" align="center" sx={{ mb: 2 }}>
              {t('paymentSuccess.premiumBody')}
            </Typography>
          </>
        ) : pollingStopped ? (
          <>
            <Typography variant="h4" gutterBottom align="center">
              {t('paymentSuccess.noUpdateTitle')}
            </Typography>
            <Typography variant="body1" align="center" sx={{ mb: 2 }}>
              <Trans
                i18nKey="paymentSuccess.noUpdateBody"
                components={{
                  link: <Link href="mailto:info@verhalen-maker.nl" underline="hover" />,
                }}
              />
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h4" gutterBottom align="center">
              {t('paymentSuccess.pendingTitle')}
            </Typography>
            <Typography variant="body1" align="center" sx={{ mb: 2 }}>
              {t('paymentSuccess.pendingBody')}
            </Typography>
            <Typography variant="body2" align="center">
              {t('paymentSuccess.pendingStatus')}
            </Typography>
          </>
        )}
      </Box>
    </Container>
  );
};

export default PaymentSuccess;
