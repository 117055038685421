import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  Button,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const EmailModal = ({
  email,
  setEmail,
  open,
  handleClose,
  handleSendEmail,
  emailSending,
  emailSuccess,
  emailError,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('emailModal.title')}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label={t('emailModal.emailLabel')}
          type="email"
          fullWidth
          variant="standard"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={emailSending}
        />
        {emailError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {emailError}
          </Alert>
        )}
        {emailSuccess && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {emailSuccess}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={emailSending}>
          {t('emailModal.cancel')}
        </Button>
        <Button onClick={handleSendEmail} disabled={emailSending || emailSuccess}>
          {emailSending ? <CircularProgress size={20} /> : t('emailModal.send')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailModal;
