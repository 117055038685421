// src/pages/Contact.js
import React from 'react';
import { Box, Typography, Link, Container } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';

const Contact = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      {/* Contactinformatie */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          mb: 4,
        }}
      >
        <Typography variant="h4" gutterBottom align="center">
          {t('contact.title')}
        </Typography>
        <Typography variant="body1" align="center">
          <Trans
            i18nKey="contact.emailPrompt"
            components={{
              email: <Link href="mailto:info@verhalen-maker.nl" underline="hover" />,
            }}
          />
        </Typography>
      </Box>

      {/* Bedrijfsgegevens */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          width: '100%',
        }}
      >
        <Typography variant="h5" gutterBottom>
          {t('contact.companyDetails.heading')}
        </Typography>
        <Typography variant="body1">
          <strong>{t('contact.companyDetails.addressLabel')}:</strong> {t('contact.companyDetails.address')}
        </Typography>
        <Typography variant="body1">
          <strong>{t('contact.companyDetails.phoneLabel')}:</strong> {t('contact.companyDetails.phone')}
        </Typography>
        <Typography variant="body1">
          <strong>{t('contact.companyDetails.kvkLabel')}:</strong> {t('contact.companyDetails.kvk')}
        </Typography>
        <Typography variant="body1">
          <strong>{t('contact.companyDetails.vatLabel')}:</strong> {t('contact.companyDetails.vat')}
        </Typography>
      </Box>
    </Container>
  );
};

export default Contact;
