import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Box,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SecurityIcon from '@mui/icons-material/Security';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BrushIcon from '@mui/icons-material/Brush';
import { useTranslation } from 'react-i18next';

let setOpenModal = null;

export const showLoginModal = () => {
  if (setOpenModal) {
    setOpenModal(true);
  }
};

const commonButtonStyles = {
  borderRadius: '10px',
  textTransform: 'none',
  fontWeight: 600,
  paddingY: 1.5,
  paddingX: 3,
};

const LoginPromptModal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { loginWithRedirect } = useAuth0();
  const location = useLocation();
  const theme = useTheme();

  useEffect(() => {
    setOpenModal = setOpen;
    return () => {
      setOpenModal = null;
    };
  }, []);

  const handleClose = () => {
    console.log('LoginPromptModal: Closing without logging in');
    setOpen(false);
  };

  const handleLogin = () => {
    console.log('LoginPromptModal: Initiating login');
    console.log('LoginPromptModal: Current location:', location.pathname + location.search);

    // Save return path in sessionStorage
    sessionStorage.setItem('returnTo', location.pathname + location.search);

    setOpen(false);
    loginWithRedirect().catch((error) => {
      console.error('LoginPromptModal: loginWithRedirect error:', error);
    });
  };

  // Handler for toggling Accordions
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 4,
          boxShadow: 24,
          backgroundColor: theme.palette.background.default,
        },
      }}
    >
      <DialogTitle sx={{ textAlign: 'center', paddingBottom: 0 }}>
        <Typography variant="h4" color="primary">
          {t('loginPromptModal.title')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {/* Introductory text with image */}
        <Grid container spacing={4} justifyContent="center" sx={{ marginTop: 2 }}>
          <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
            <img
              src="/jasmijn.png"
              alt={t('loginPromptModal.imageAlt')}
              style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="body1" gutterBottom>
              {t('loginPromptModal.intro')}
            </Typography>
          </Grid>
        </Grid>

        {/* Accordion Items */}
        <Box mt={4}>
          {/* Privacy and Security Accordion */}
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ backgroundColor: theme.palette.background.paper }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box display="flex" alignItems="center">
                <SecurityIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="h6">{t('loginPromptModal.accordion.privacy.title')}</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={8}>
                  <Typography variant="body2">
                    {t('loginPromptModal.accordion.privacy.content')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <img
                    src="/privacy.png"
                    alt={t('loginPromptModal.accordion.privacy.imageAlt')}
                    style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Mijn Bibliotheek Accordion */}
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={{ backgroundColor: theme.palette.background.paper }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box display="flex" alignItems="center">
                <LibraryBooksIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="h6">{t('loginPromptModal.accordion.library.title')}</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={8}>
                  <Typography variant="body2">
                    {t('loginPromptModal.accordion.library.content')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <img
                    src="/library.png"
                    alt={t('loginPromptModal.accordion.library.imageAlt')}
                    style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Verhalen Uitbreiden Accordion */}
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} sx={{ backgroundColor: theme.palette.background.paper }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box display="flex" alignItems="center">
                <AddCircleIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="h6">{t('loginPromptModal.accordion.expand.title')}</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={8}>
                  <Typography variant="body2">
                    {t('loginPromptModal.accordion.expand.content')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <img
                    src="/story_expansion.png"
                    alt={t('loginPromptModal.accordion.expand.imageAlt')}
                    style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Extra Functies Accordion */}
          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} sx={{ backgroundColor: theme.palette.background.paper }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box display="flex" alignItems="center">
                <BrushIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="h6">{t('loginPromptModal.accordion.extra.title')}</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={8}>
                  <Typography variant="body2">
                    {t('loginPromptModal.accordion.extra.content')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <img
                    src="/headphones.png"
                    alt={t('loginPromptModal.accordion.extra.imageAlt')}
                    style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', padding: 2 }}>
        <Button
          onClick={handleLogin}
          variant="contained"
          color="primary"
          startIcon={<EmojiEmotionsIcon />}
          aria-label={t('loginPromptModal.button.aria')}
          sx={{
            ...commonButtonStyles,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
        >
          {t('loginPromptModal.button.text')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoginPromptModal;
