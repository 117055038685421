import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomSnackbar from '../../components/CustomSnackbar';

const Snackbars = ({
  openLoadingSnackbar,
  openErrorSnackbar,
  openSuccessSnackbar,
  openShareSnackbar,
  handleCloseSnackbar,
  errorMessage,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {/* Loading Snackbar */}
      <CustomSnackbar
        open={openLoadingSnackbar}
        handleClose={handleCloseSnackbar}
        severity="info"
        message={t('snackbars.loading')}
        autoHideDuration={null}
      />
      {/* Error Snackbar */}
      <CustomSnackbar
        open={openErrorSnackbar}
        handleClose={handleCloseSnackbar}
        severity="error"
        message={errorMessage || t('snackbars.error')}
      />
      {/* Success Snackbar */}
      <CustomSnackbar
        open={openSuccessSnackbar}
        handleClose={handleCloseSnackbar}
        severity="success"
        message={t('snackbars.success')}
      />
      {/* Share Snackbar */}
      {openShareSnackbar && (
        <CustomSnackbar
          open={openShareSnackbar}
          handleClose={handleCloseSnackbar}
          severity="info"
          message={t('snackbars.share')}
        />
      )}
    </>
  );
};

export default Snackbars;
