import React from 'react';
import {
  Typography,
  TextField,
  Box,
  Slider,
  InputAdornment,
  IconButton,
  Button,
  ButtonGroup,
} from '@mui/material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { getLeesniveaus } from '../constants/leesniveaus';

/**
 * getStepContent:
 * This function returns the content for a given step.
 * It expects:
 *  - step: number (the current step index)
 *  - formValues: object (the current form values)
 *  - handleChange: function (to update form values)
 *  - brand: string (to determine brand-specific content)
 *  - t: translation function (from useTranslation)
 *
 * Ensure you pass t from your parent component.
 */
function getStepContent(step, formValues, handleChange, brand, t) {
  // Retrieve translated arrays using t with returnObjects option
  const verhaallocatieExamples = t('verhaallocatie_examples', { returnObjects: true });
  const verhaallijnExamples = t('verhaallijn_examples', { returnObjects: true });
  const verhaallijnExamplesYoungAdult = t('verhaallijn_examples_youngadult', { returnObjects: true });

  // Helper: pick a random example from an array
  const getRandomExample = (examples) => {
    if (!examples || examples.length === 0) return '';
    const randomIndex = Math.floor(Math.random() * examples.length);
    return examples[randomIndex];
  };

  // Handlers to set a random value
  const handleFetchVerhaallocatie = () => {
    const randomValue = getRandomExample(verhaallocatieExamples);
    handleChange('verhaallocatie')({
      target: { value: randomValue },
    });
  };

  const handleFetchVerhaallijn = () => {
    const chosenVerhaallijnExamples =
      brand === 'storybuzz'
        ? verhaallijnExamplesYoungAdult
        : verhaallijnExamples;
    const randomValue = getRandomExample(chosenVerhaallijnExamples);
    handleChange('verhaallijn')({
      target: { value: randomValue },
    });
  };

  // Get leesniveaus based on the brand
  const leesniveaus = getLeesniveaus(brand);

  switch (step) {
    case 0:
      return (
        <div>
          <Typography variant="h6">
            {t('getStepContent.step0.title', 'Geef aan wie de hoofdrolspelers van het verhaal zijn')}
          </Typography>
          <TextField
            label={t('getStepContent.step0.label', 'Namen, leeftijden en beschrijvingen van de kinderen')}
            placeholder={t(
              'getStepContent.step0.placeholder',
              'Bijv. Jan (X jaar, houdt van ...), Lisa (X jaar, bang voor..)'
            )}
            multiline
            fullWidth
            margin="normal"
            value={formValues.hoofdrolspelers}
            onChange={handleChange('hoofdrolspelers')}
          />
        </div>
      );

    case 1:
      return (
        <div>
          <Typography variant="h6">
            {t('getStepContent.step1.title', 'Geef aan waar het verhaal zich af gaat spelen')}
          </Typography>
          <TextField
            label={t('getStepContent.step1.label', 'Waar speelt het verhaal zich af?')}
            placeholder={t(
              'getStepContent.step1.placeholder',
              'Bijv. Een magisch bos, de ruimte, een middeleeuws kasteel'
            )}
            fullWidth
            margin="normal"
            value={formValues.verhaallocatie}
            onChange={handleChange('verhaallocatie')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label={t('getStepContent.step1.randomButton', 'Willekeurige verhaallocatie')}
                    onClick={handleFetchVerhaallocatie}
                    edge="end"
                    color="primary"
                  >
                    <AutoFixHighIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      );

    case 2:
      return (
        <div>
          <Typography variant="h6">
            {t('getStepContent.step2.title', 'Geef een richting aan het verhaal, waar wil je dat het ongeveer over gaat?')}
          </Typography>
          <TextField
            label={t('getStepContent.step2.label', 'Waar gaat het verhaal over?')}
            placeholder={t(
              'getStepContent.step2.placeholder',
              'Bijv. Een avontuur om een verloren schat te vinden'
            )}
            multiline
            fullWidth
            margin="normal"
            value={formValues.verhaallijn}
            onChange={handleChange('verhaallijn')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label={t('getStepContent.step2.randomButton', 'Willekeurige verhaallijn')}
                    onClick={handleFetchVerhaallijn}
                    edge="end"
                    color="primary"
                  >
                    <AutoFixHighIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      );

    case 3:
      return (
        <div>
          <Typography variant="h6">
            {t('getStepContent.step3.title', 'Wat is het leesniveau?')}
          </Typography>
          <Box
            sx={{
              width: '100%',
              mt: 4,
              px: 2,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <ButtonGroup
              variant="outlined"
              color="primary"
              aria-label="leesniveau button group"
            >
              {leesniveaus.map((niveau, index) => (
                <Button
                  key={index}
                  variant={
                    formValues.leesniveau === niveau.label
                      ? 'contained'
                      : 'outlined'
                  }
                  onClick={() =>
                    handleChange('leesniveau')({
                      target: { value: niveau.label },
                    })
                  }
                >
                  {niveau.label}
                </Button>
              ))}
            </ButtonGroup>
          </Box>
        </div>
      );

    case 4:
      return (
        <div>
          <Typography variant="h6">
            {t('getStepContent.step4.title', 'Hoe lang moet het verhaal zijn?')}
          </Typography>
          <Box sx={{ width: '100%', mt: 4 }}>
            <Slider
              value={formValues.verhaalLengte}
              onChange={handleChange('verhaalLengte')}
              aria-labelledby="verhaalLengte-slider"
              valueLabelDisplay="auto"
              step={100}
              marks={[
                { value: 300, label: '300' },
                { value: 500, label: '500' },
                { value: 800, label: '800' },
                { value: 1000, label: '1000' },
              ]}
              min={300}
              max={1000}
            />
            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
              {t('getStepContent.step4.length', { length: formValues.verhaalLengte })}
            </Typography>
          </Box>
        </div>
      );

    default:
      return t('getStepContent.unknownStep', 'Onbekende stap');
  }
}

export default getStepContent;
