// src/pages/OverOnsPage.js
import React from 'react';
import { Link as MuiLink, Typography, Container, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

const OverOnsPage = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom align="center">
        {t('overOnsPage.title')}
      </Typography>

      {/* Section 1 */}
      <Typography variant="h5" gutterBottom>
        {t('overOnsPage.section1.heading')}
      </Typography>
      <Typography variant="body1" paragraph>
        {t('overOnsPage.section1.paragraph1')}
      </Typography>
      <Typography variant="body1" paragraph>
        {t('overOnsPage.section1.paragraph2')}
      </Typography>
      <Typography variant="body1" paragraph>
        {t('overOnsPage.section1.paragraph3')}
      </Typography>

      {/* Section 2 */}
      <Typography variant="h5" gutterBottom>
        {t('overOnsPage.section2.heading')}
      </Typography>
      <Typography variant="body1" paragraph>
        {t('overOnsPage.section2.paragraph1')}
      </Typography>
      <Typography variant="body1" paragraph>
        {t('overOnsPage.section2.paragraph2')}
      </Typography>

      {/* Section 3 */}
      <Typography variant="h5" gutterBottom>
        {t('overOnsPage.section3.heading')}
      </Typography>
      <Typography variant="body1" paragraph>
        {t('overOnsPage.section3.paragraph1')}
      </Typography>
      <Typography variant="body1" paragraph>
        {t('overOnsPage.section3.paragraph2')}
      </Typography>

      {/* Section 4 */}
      <Typography variant="h5" gutterBottom>
        {t('overOnsPage.section4.heading')}
      </Typography>
      <Typography variant="body1" paragraph>
        <Trans
          i18nKey="overOnsPage.section4.paragraph1"
          components={{
            email: <MuiLink href="mailto:info@verhalen-maker.nl" />,
          }}
        />
      </Typography>

      {/* Image */}
      <Box display="flex" justifyContent="center" mt={4}>
        <Box
          component="img"
          src="/profileimage.png"
          alt={t('overOnsPage.imageAlt', 'Foto van [Naam]')}
          sx={{
            width: { xs: '150px', sm: '200px' },
            height: 'auto',
            borderRadius: '50%',
            boxShadow: 3,
            objectFit: 'cover',
          }}
        />
      </Box>

      {/* Footer Links */}
      <Box sx={{ mt: 6, textAlign: 'center', borderTop: '1px solid #ccc', pt: 2 }}>
        <Typography variant="body2">
          <MuiLink component={Link} to="/algemene-voorwaarden" underline="hover" sx={{ mx: 1 }}>
            {t('overOnsPage.footer.terms')}
          </MuiLink>
          |
          <MuiLink component={Link} to="/privacybeleid" underline="hover" sx={{ mx: 1 }}>
            {t('overOnsPage.footer.privacy')}
          </MuiLink>
        </Typography>
        <Typography variant="caption" color="textSecondary" display="block" sx={{ mt: 1 }}>
          {t('overOnsPage.footer.copyright', { year: new Date().getFullYear() })}
        </Typography>
      </Box>
    </Container>
  );
};

export default OverOnsPage;
