import React from 'react';
import { useBrand } from '../contexts/BrandContext';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Grid,
  styled,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

// Styled component for each brand option
const BrandOption = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create(['transform', 'box-shadow'], {
    duration: theme.transitions.duration.short,
  }),
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[4],
  },
}));

// Styled component to maintain square aspect ratio and handle image cropping
const ImageContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  paddingTop: '100%', // 1:1 Aspect Ratio
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  marginBottom: theme.spacing(2),
}));

const BrandImage = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}));

function BrandSelectionModal({ open, onClose }) {
  const { t } = useTranslation();
  const { changeBrand } = useBrand();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSelectBrand = (brandType) => {
    changeBrand(brandType);
    onClose(true); // Notify parent component of successful selection
  };

  return (
    <Dialog 
      open={open}
      maxWidth="sm"
      fullWidth
      disableEscapeKeyDown
      PaperProps={{
        sx: (theme) => ({
          borderRadius: theme.shape.borderRadius * 2,
          padding: theme.spacing(2),
        }),
      }}
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          backdropFilter: 'blur(4px)',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          typography: 'h4',
          padding: theme.spacing(3),
        }}
      >
        {t('brandSelectionModal.title')}
      </DialogTitle>
      
      <DialogContent>
        <Grid container spacing={4}>
          {/* Basisschool Option */}
          <Grid item xs={12} sm={6}>
            <BrandOption onClick={() => handleSelectBrand('verhalenmaker')}>
              <ImageContainer>
                <BrandImage
                  src="/adventure.png"
                  alt={t('brandSelectionModal.primaryAlt')}
                />
              </ImageContainer>
              <Button 
                fullWidth
                variant="contained"
                color="primary"
                size="large"
              >
                {t('brandSelectionModal.primary')}
              </Button>
            </BrandOption>
          </Grid>

          {/* Middelbare school Option */}
          <Grid item xs={12} sm={6}>
            <BrandOption onClick={() => handleSelectBrand('storybuzz')}>
              <ImageContainer>
                <BrandImage
                  src="/storybuzz_logo.webp"
                  alt={t('brandSelectionModal.secondaryAlt')}
                />
              </ImageContainer>
              <Button 
                fullWidth
                variant="contained"
                color="primary"
                size="large"
              >
                {t('brandSelectionModal.secondary')}
              </Button>
            </BrandOption>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions
        sx={{
          justifyContent: 'center',
          padding: theme.spacing(3),
          typography: 'body2',
          color: 'text.secondary',
        }}
      >
        {t('brandSelectionModal.footer')}
      </DialogActions>
    </Dialog>
  );
}

export default BrandSelectionModal;
