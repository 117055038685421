import React from 'react';
import { Box, CircularProgress, Typography, Skeleton, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ImageSection = ({ loadingImage, imageError, base64Image, imageUrl, isPremiumStory }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        my: 4,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {loadingImage ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      ) : imageError ? (
        <Typography color="error" align="center" sx={{ my: 4 }}>
          {t('imageSection.imageError')}
        </Typography>
      ) : base64Image ? (
        <Box
          component="img"
          src={base64Image}
          alt={t('imageSection.altText')}
          sx={{
            width: '100%',
            height: 'auto',
            borderRadius: 2,
          }}
        />
      ) : !imageUrl && !isPremiumStory ? (
        <Skeleton
          variant="rectangular"
          width="100%"
          height={200}
          sx={{ borderRadius: 2 }}
        />
      ) : null}
      {!imageUrl && !isPremiumStory && (
        <Alert severity="info" sx={{ width: '100%', mt: 2 }}>
          {t('imageSection.alertMessage')}
        </Alert>
      )}
    </Box>
  );
};

export default ImageSection;
