import React, { useState, useEffect } from 'react';
import { Box, Button, Slide, Stepper, Step, StepLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';

function StoryForm({
  activeStep,
  steps,
  getStepContent,
  handleNext,
  handleBack,
  isLoading,
  formValues,
}) {
  const { t } = useTranslation();
  const [inProp, setInProp] = useState(true);
  const [slideDirection, setSlideDirection] = useState('right');

  // Always set verhaalLengte to 1000
  useEffect(() => {
    formValues.verhaalLengte = 1000;
  }, [formValues]);

  const onNext = () => {
    setSlideDirection('left');
    setInProp(false);
    setTimeout(() => {
      handleNext();
      setSlideDirection('left');
      setInProp(true);
    }, 300);
  };

  const onBack = () => {
    setSlideDirection('right');
    setInProp(false);
    setTimeout(() => {
      handleBack();
      setSlideDirection('right');
      setInProp(true);
    }, 300);
  };

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '600px',
        margin: 'auto',
        p: 2,
        overflowX: 'hidden',
        minHeight: '400px',
      }}
    >
      <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel
              sx={{
                fontSize: '0.7rem',
                '& .MuiStepLabel-label': { fontSize: '0.7rem' },
              }}
            >
              {t(label)}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <Slide direction={slideDirection} in={inProp} timeout={300}>
        <Box>
          {getStepContent(activeStep)}
          <Box
            sx={{
              mt: 2,
              mb: 4,
              display: 'flex',
              justifyContent: activeStep !== 0 ? 'space-between' : 'flex-end',
            }}
          >
            {activeStep !== 0 && (
              <Button onClick={onBack} color="secondary">
                {t('storyForm.back')}
              </Button>
            )}
            {activeStep !== steps.length - 1 && (
              <Button
                variant="contained"
                onClick={onNext}
                disabled={
                  (activeStep === 0 && !formValues.hoofdrolspelers.trim()) ||
                  (activeStep === 1 && !formValues.verhaallocatie.trim()) ||
                  (activeStep === 2 && !formValues.verhaallijn.trim()) ||
                  (activeStep === 3 && !formValues.leesniveau) ||
                  isLoading ||
                  activeStep >= steps.length - 1
                }
                color="primary"
                sx={{
                  borderRadius: '12px',
                  textTransform: 'none',
                  fontWeight: 600,
                }}
              >
                {isLoading ? t('storyForm.loading') : t('storyForm.next')}
              </Button>
            )}
          </Box>
        </Box>
      </Slide>
    </Box>
  );
}

export default StoryForm;
