// src/pages/AlgemeneVoorwaardenPage.js
import React from 'react';
import { Typography, Box, Container } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';

const AlgemeneVoorwaardenPage = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom align="center">
        {t('algemeneVoorwaarden.title')}
      </Typography>

      <Box sx={{ mt: 4, textAlign: 'left' }}>
        {/* Artikel 1 - Definities */}
        <Typography variant="h6" gutterBottom>
          {t('algemeneVoorwaarden.article1.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('algemeneVoorwaarden.article1.introduction')}
        </Typography>
        <Typography variant="body1" component="ul">
          <li>
            <strong>{t('algemeneVoorwaarden.article1.item1.title')}</strong>{' '}
            <Trans
              i18nKey="algemeneVoorwaarden.article1.item1.content"
              components={{
                link: <a href="https://www.verhalen-maker.nl/" target="_blank" rel="noopener noreferrer" />,
              }}
            />
          </li>
          <li>
            <strong>{t('algemeneVoorwaarden.article1.item2.title')}</strong>{' '}
            {t('algemeneVoorwaarden.article1.item2.content')}
          </li>
          <li>
            <strong>{t('algemeneVoorwaarden.article1.item3.title')}</strong>{' '}
            {t('algemeneVoorwaarden.article1.item3.content')}
          </li>
          <li>
            <strong>{t('algemeneVoorwaarden.article1.item4.title')}</strong>{' '}
            {t('algemeneVoorwaarden.article1.item4.content')}
          </li>
        </Typography>

        {/* Artikel 2 - Toepasselijkheid */}
        <Typography variant="h6" gutterBottom>
          {t('algemeneVoorwaarden.article2.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('algemeneVoorwaarden.article2.item1')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('algemeneVoorwaarden.article2.item2')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('algemeneVoorwaarden.article2.item3')}
        </Typography>

        {/* Artikel 3 - Aanbod en Overeenkomst */}
        <Typography variant="h6" gutterBottom>
          {t('algemeneVoorwaarden.article3.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('algemeneVoorwaarden.article3.item1')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('algemeneVoorwaarden.article3.item2')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('algemeneVoorwaarden.article3.item3')}
        </Typography>

        {/* Artikel 4 - Prijzen en Betaling */}
        <Typography variant="h6" gutterBottom>
          {t('algemeneVoorwaarden.article4.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('algemeneVoorwaarden.article4.item1')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('algemeneVoorwaarden.article4.item2')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('algemeneVoorwaarden.article4.item3')}
        </Typography>

        {/* Artikel 5 - Levering en Uitvoering */}
        <Typography variant="h6" gutterBottom>
          {t('algemeneVoorwaarden.article5.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('algemeneVoorwaarden.article5.item1')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('algemeneVoorwaarden.article5.item2')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('algemeneVoorwaarden.article5.item3')}
        </Typography>

        {/* Artikel 6 - Herroepingsrecht */}
        <Typography variant="h6" gutterBottom>
          {t('algemeneVoorwaarden.article6.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('algemeneVoorwaarden.article6.item1')}
        </Typography>
        <Typography variant="body1" paragraph>
          <Trans
            i18nKey="algemeneVoorwaarden.article6.item2"
            components={{
              email: <a href="mailto:lisanne@verhalen-maker.nl" />,
            }}
          />
        </Typography>
        <Typography variant="body1" paragraph>
          {t('algemeneVoorwaarden.article6.item3')}
        </Typography>

        {/* Artikel 7 - Aansprakelijkheid */}
        <Typography variant="h6" gutterBottom>
          {t('algemeneVoorwaarden.article7.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('algemeneVoorwaarden.article7.item1')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('algemeneVoorwaarden.article7.item2')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('algemeneVoorwaarden.article7.item3')}
        </Typography>

        {/* Artikel 8 - Intellectueel Eigendom */}
        <Typography variant="h6" gutterBottom>
          {t('algemeneVoorwaarden.article8.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('algemeneVoorwaarden.article8.item1')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('algemeneVoorwaarden.article8.item2')}
        </Typography>

        {/* Artikel 9 - Klachtenregeling */}
        <Typography variant="h6" gutterBottom>
          {t('algemeneVoorwaarden.article9.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          <Trans
            i18nKey="algemeneVoorwaarden.article9.item1"
            components={{
              email: <a href="mailto:lisanne@verhalen-maker.nl" />,
            }}
          />
        </Typography>
        <Typography variant="body1" paragraph>
          {t('algemeneVoorwaarden.article9.item2')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('algemeneVoorwaarden.article9.item3')}
        </Typography>

        {/* Artikel 10 - Toepasselijk Recht en Geschillen */}
        <Typography variant="h6" gutterBottom>
          {t('algemeneVoorwaarden.article10.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('algemeneVoorwaarden.article10.item1')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('algemeneVoorwaarden.article10.item2')}
        </Typography>

        {/* Artikel 11 - Wijziging van de Algemene Voorwaarden */}
        <Typography variant="h6" gutterBottom>
          {t('algemeneVoorwaarden.article11.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('algemeneVoorwaarden.article11.item1')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('algemeneVoorwaarden.article11.item2')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('algemeneVoorwaarden.article11.item3')}
        </Typography>

        <Typography variant="body2" color="textSecondary" align="right" sx={{ mt: 4 }}>
          {t('algemeneVoorwaarden.lastUpdated')}
        </Typography>
      </Box>
    </Container>
  );
};

export default AlgemeneVoorwaardenPage;
