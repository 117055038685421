import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import CssBaseline from '@mui/material/CssBaseline';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter as Router } from 'react-router-dom';
import { BrandProvider } from './contexts/BrandContext';
import './i18n'; // <-- Import i18n config (no need to store in a variable)

const onRedirectCallback = () => {
  const returnTo = sessionStorage.getItem('returnTo') || window.location.pathname;
  const cleanUrl = window.location.origin + returnTo;
  window.location.replace(cleanUrl);
  sessionStorage.removeItem('returnTo');
};

const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_AUDIENCE,
} = process.env;

if (!REACT_APP_AUTH0_DOMAIN || !REACT_APP_AUTH0_CLIENT_ID || !REACT_APP_AUTH0_AUDIENCE) {
  console.error(
    'Missing one or more required environment variables: REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_AUDIENCE'
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={REACT_APP_AUTH0_DOMAIN}
      clientId={REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: REACT_APP_AUTH0_AUDIENCE,
        scope: 'openid profile email read:current_user update:current_user_roles',
      }}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      <BrandProvider>
        <CssBaseline />
        <Router>
          <App />
        </Router>
      </BrandProvider>
    </Auth0Provider>
  </React.StrictMode>
);
