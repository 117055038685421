// src/constants/leesniveaus.js

export const allLeesniveaus = [
  { value: 2, label: 'E3' },
  { value: 3, label: 'M4' },
  { value: 4, label: 'E4' },
  { value: 5, label: 'M5' },
  { value: 6, label: 'E5' },
  { value: 7, label: 'M6' },
  { value: 8, label: 'E6' },
  { value: 9, label: '7+' },
  { value: 10, label: '1F' },
  { value: 11, label: '2F' },
  { value: 12, label: '3F' },
  { value: 13, label: '4F' },
];

/**
 * Returns filtered leesniveaus based on the provided brand.
 * @param {string} brand - The brand type (e.g., 'storybuzz').
 * @returns {Array} - Filtered array of leesniveaus.
 */
export const getLeesniveaus = (brand) => {
  return brand === 'storybuzz'
    ? allLeesniveaus.filter((n) => n.value >= 10) // F1–F4
    : allLeesniveaus.filter((n) => n.value < 10);  // E3–7+
};
