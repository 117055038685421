// src/components/StoryDisplay/QuizComponent.js
import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Modal,
  Paper,
  Fade as MuiFade,
} from '@mui/material';
import { styled } from '@mui/system';
import { UserStatusContext } from '../../contexts/UserStatusContext';
import Confetti from 'react-confetti';
import { useTranslation, Trans } from 'react-i18next';

const QuizContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  textAlign: 'center',
}));

const AnswerButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  width: '100%',
  textTransform: 'none',
}));

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '80%', sm: 400, md: 500 },
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

function QuizComponent({ uuid }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [quizData, setQuizData] = useState(null);
  const [error, setError] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [score, setScore] = useState(100);
  const [modalOpen, setModalOpen] = useState(false);
  const [isQuizCompleted, setIsQuizCompleted] = useState(false);
  const [confettiDimensions, setConfettiDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const { subscriptionStatus, totalQuizPoints } = useContext(UserStatusContext);

  useEffect(() => {
    console.log('Context Data:', { subscriptionStatus, totalQuizPoints });
  }, [subscriptionStatus, totalQuizPoints]);

  useEffect(() => {
    const handleResize = () => {
      setConfettiDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      console.log('Window Resized:', { width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchQuiz = async () => {
      try {
        const response = await fetch('/api/get-quiz', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ uuid }),
        });
        if (response.status === 404) {
          setQuizData(null);
          setError(null);
          console.log('No quiz found for the given UUID.');
        } else if (response.status === 200) {
          const data = await response.json();
          if (
            typeof data.question !== 'string' ||
            !Array.isArray(data.answers) ||
            typeof data.correct_answer !== 'string'
          ) {
            throw new Error(t('quiz.unexpectedFormat'));
          }
          if (data.answers.length !== 4) {
            throw new Error(t('quiz.unexpectedFormat'));
          }
          setQuizData(data);
          setError(null);
          console.log('Quiz data fetched successfully:', data);
        } else {
          const errorData = await response.json();
          throw new Error(errorData.error || t('quiz.fetchFailed'));
        }
      } catch (err) {
        setError(err.message || t('quiz.fetchFailed'));
        console.error('Error fetching quiz data:', err);
      } finally {
        setLoading(false);
      }
    };
    fetchQuiz();
  }, [uuid, t]);

  const handleAnswerClick = async (answer) => {
    if (selectedAnswers.includes(answer) || isQuizCompleted) return;
    if (answer === quizData.correct_answer) {
      setIsQuizCompleted(true);
      setModalOpen(true);
      console.log('Correct answer selected:', answer);
      try {
        const response = await fetch('/api/flag-quiz', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ uuid, points_received: score }),
        });
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || t('quiz.fetchFailed'));
        }
        console.log('Quiz result submitted successfully.');
      } catch (err) {
        console.error('Error submitting quiz result:', err);
      }
    } else {
      setSelectedAnswers((prev) => [...prev, answer]);
      setScore((prev) => Math.max(prev - 25, 0));
      console.log('Incorrect answer selected:', answer, 'New score:', Math.max(score - 25, 0));
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  if (loading) {
    return (
      <Box sx={{ width: '100%', mb: 4, textAlign: 'center' }}>
        <Typography variant="h6">{t('quiz.loading')}</Typography>
        <CircularProgress sx={{ mt: 2 }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ width: '100%', mb: 4, textAlign: 'center' }}>
        <Typography variant="h6" color="error">{error}</Typography>
      </Box>
    );
  }

  if (!quizData) {
    return null;
  }

  return (
    <Box>
      {isQuizCompleted && (
        <Box sx={{ position: 'fixed', top: 0, left: 0, zIndex: 1301 }}>
          <Confetti
            width={confettiDimensions.width}
            height={confettiDimensions.height}
            numberOfPieces={1000}
            recycle={false}
            gravity={0.1}
            colors={['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']}
          />
        </Box>
      )}
      <QuizContainer elevation={3}>
        <Typography variant="h5" gutterBottom>{t('quiz.title')}</Typography>
        <Typography variant="subtitle1" gutterBottom>{quizData.question}</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          {quizData.answers.map((answer, index) => (
            <AnswerButton
              key={index}
              variant="contained"
              color={
                isQuizCompleted
                  ? answer === quizData.correct_answer
                    ? 'success'
                    : selectedAnswers.includes(answer)
                    ? 'error'
                    : 'primary'
                  : 'primary'
              }
              onClick={() => handleAnswerClick(answer)}
              disabled={isQuizCompleted || selectedAnswers.includes(answer)}
            >
              {answer}
            </AnswerButton>
          ))}
        </Box>
      </QuizContainer>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        closeAfterTransition
        aria-labelledby="score-modal-title"
        aria-describedby="score-modal-description"
      >
        <MuiFade in={modalOpen}>
          <Box sx={modalStyle}>
            <Typography id="score-modal-title" variant="h4" gutterBottom>
              {score === 100
                ? t('quiz.modal.excellent')
                : score >= 50
                ? t('quiz.modal.good')
                : t('quiz.modal.tryAgain')}
            </Typography>
            <Typography id="score-modal-description" variant="h6" gutterBottom>
              <Trans
                i18nKey="quiz.modal.score"
                values={{ score }}
                components={{ strong: <strong /> }}
              />
            </Typography>
            <Typography variant="h6" gutterBottom>
              <Trans
                i18nKey="quiz.modal.totalScore"
                values={{ total: totalQuizPoints + score }}
                components={{ strong: <strong /> }}
              />
            </Typography>
            <Button variant="contained" onClick={handleCloseModal} sx={{ mt: 2 }}>
              {t('quiz.modal.close')}
            </Button>
          </Box>
        </MuiFade>
      </Modal>
    </Box>
  );
}

export default QuizComponent;
