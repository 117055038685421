// src/pages/BasisschoolRedirect.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBrand } from '../contexts/BrandContext';

const BasisschoolRedirect = () => {
  const navigate = useNavigate();
  const { changeBrand } = useBrand();

  useEffect(() => {
    changeBrand('verhalenmaker');
    // Navigate to home after setting brand
    navigate('/', { replace: true });
  }, [changeBrand, navigate]);

  return null;
};

export default BasisschoolRedirect;
