import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <Box textAlign="center" mt={10}>
      <Typography variant="h4" gutterBottom>
        {t('notFoundPage.title')}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {t('notFoundPage.subtitle')}
      </Typography>
      <Button variant="contained" color="primary" component={Link} to="/">
        {t('notFoundPage.button')}
      </Button>
    </Box>
  );
};

export default NotFoundPage;
