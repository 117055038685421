// src/contexts/BrandContext.js

import React, { createContext, useContext, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { getTheme } from '../theme';

const BrandContext = createContext();

export function BrandProvider({ children }) {
  // Initialize brand from localStorage or empty string if none
  const [brand, setBrand] = useState(() => localStorage.getItem('brand') || '');

  // When brand changes, store in localStorage and in state
  const changeBrand = (newBrand) => {
    localStorage.setItem('brand', newBrand);
    setBrand(newBrand);
  };

  // If brand is empty, we can temporarily default or show a "neutral" theme
  // (in this example we'll default to verhalenmaker if brand is not set yet)
  const theme = brand ? getTheme(brand) : getTheme('verhalenmaker');

  return (
    <BrandContext.Provider value={{ brand, changeBrand }}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </BrandContext.Provider>
  );
}

export function useBrand() {
  return useContext(BrandContext);
}
