import React from 'react';
import { Backdrop, Box, LinearProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function LoadingBackdrop({ isLoading, progress }) {
  const { t } = useTranslation();

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}
    >
      <Box sx={{ width: '80%' }}>
        <LinearProgress
          variant="determinate"
          value={progress}
          aria-label={t('loadingBackdrop.aria', { progress: Math.round(progress) })}
        />
        <Typography variant="body2" align="center" sx={{ mt: 2 }}>
          {progress < 100
            ? t('loadingBackdrop.loading', { progress: Math.round(progress) })
            : t('loadingBackdrop.completed')}
        </Typography>
      </Box>
    </Backdrop>
  );
}

export default LoadingBackdrop;
