import React from 'react';
import { Box, Button } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import { useAuth0 } from '@auth0/auth0-react';
import { useAbonnementenModal } from '../../components/AbonnementenModal';
import { useTranslation } from 'react-i18next';

const CreateStoryButtons = ({
  isFormValid,
  isLoading,
  handleStandardSubmit,
  handlePremiumSubmit,
  subscriptionStatus,
  isAuthenticated,
  openLoginModal,
}) => {
  const { openAbonnementenModal } = useAbonnementenModal();
  const { loginWithRedirect } = useAuth0();
  const { t } = useTranslation();

  let buttonContent = null;

  if (!isAuthenticated) {
    buttonContent = (
      <Button
        variant="contained"
        color="primary"
        onClick={openLoginModal}
        disabled={isLoading}
        aria-label={t('createStoryButtons.loginAria')}
        sx={{
          borderRadius: '10px',
          textTransform: 'none',
          fontWeight: 600,
          py: 1.5,
          px: 3,
        }}
      >
        {t('createStoryButtons.loginText')}
      </Button>
    );
  } else if (isAuthenticated && subscriptionStatus !== 'premium') {
    buttonContent = (
      <Button
        variant="contained"
        color="primary"
        onClick={openAbonnementenModal}
        disabled={isLoading}
        aria-label={t('createStoryButtons.nonPremiumAria')}
        sx={{
          borderRadius: '10px',
          textTransform: 'none',
          fontWeight: 600,
          py: 1.5,
          px: 3,
          cursor: 'pointer',
          backgroundColor: 'grey.500',
          color: 'white',
          '&:hover': {
            backgroundColor: 'grey.600',
          },
        }}
      >
        {t('createStoryButtons.nonPremiumText')}
      </Button>
    );
  } else if (isAuthenticated && subscriptionStatus === 'premium') {
    buttonContent = (
      <Button
        variant="contained"
        startIcon={<CreateIcon />}
        onClick={handlePremiumSubmit}
        disabled={!isFormValid || isLoading}
        aria-label={t('createStoryButtons.premiumAria')}
        sx={{
          borderRadius: '10px',
          textTransform: 'none',
          fontWeight: 600,
          py: 1.5,
          px: 3,
          animation: isFormValid ? 'bounce 1s infinite' : 'none',
        }}
      >
        {t('createStoryButtons.premiumText')}
      </Button>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        mb: 6,
        mt: 4,
        width: '100%',
        flexWrap: 'wrap',
        gap: 2,
      }}
    >
      {buttonContent}
    </Box>
  );
};

export default CreateStoryButtons;
